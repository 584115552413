<template>
  <div class="body-1">
    <p>The page you're looking for cannot be found.</p>
  </div>
</template>

<script>
export default {};
</script>

